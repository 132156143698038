import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface SlLetContext {
	$implicit: any;
	slLet: any;
}

@Directive({
	selector: '[slLet]',
	standalone: true
})
export class LetDirective {
	@Input() set slLet(value: any) {
		this.#viewContainerRef.clear();
		this.#viewContainerRef.createEmbeddedView(this.#templateRef, {
			$implicit: value,
			slLet: value
		});
	}
	readonly #viewContainerRef = inject(ViewContainerRef);
	readonly #templateRef = inject(TemplateRef) as TemplateRef<SlLetContext>;

}
